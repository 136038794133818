<template>
  <div :style="{ backgroundImage: `url(${bannerImage})` }">
    <loading :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <!-- User Interface controls -->
    <b-alert v-model="flagAlertProfile" variant="warning" dismissible>
          ¡Aún no cuentas con perfil para ver tu programación! Ir a
          <b-link @click="goTo('createProfile')">Crear perfil</b-link>
    </b-alert>
    <b-alert v-model="flagAlertPetProfile" variant="warning" dismissible>
          ¡Aún no cuentas con mascotas registradas para ver tu programación! Ir a
          <b-link @click="goTo('createPet')">Crear mascotas</b-link>
    </b-alert>
    <b-alert v-model="flagAlertSchedule" variant="warning" dismissible>
      ¡No tienes servicios programados para tu mascota! 
    </b-alert>
    <b-alert v-model="flagDataUpdated" variant="success" dismissible>
          ¡Datos actualizados!           
    </b-alert>

    <h2 class="title">Ejecución de servicios</h2>
    <p>Encuentra aquí el detalle de la ejecución de servicios.</p>

    <section 
      v-if="!flagAlertProfile && 
        !flagAlertPetProfile && 
        ! flagAlertSchedule"
        style="min-height:100vh">

      <b-row>
        <b-col lg="8" offset-lg="2" style="text-align: right">
          <b-button 
            class="s-bg s-bg-text mr-1"
            size="md"            
            @click="refresh()">
            <strong>Refrescar</strong>                   
          </b-button> 
        </b-col>
      </b-row>

        <b-row v-for="item in items" v-bind:key="item.id">
        <b-col lg="8" offset-lg="2">
          <CCard
            class="mb-0"
            :style="`margin-top:0.5rem`"            
            >
            <CCardHeader >
              <b-row>
                <b-col lg="6">
                  <h5>{{item.userObs}}</h5>
                </b-col>
                <b-col lg="6" >
                  <CIcon 
                    name="cilCircle" 
                    size="md" 
                    height="48" 
                    alt="Logo" 
                    :style="colorHeader(item.stateId)"/>                  
                  {{item.stateName}}
                </b-col>
                <b-col lg="6">
                  <strong>Fecha:</strong> {{item.dateStart}}
                </b-col>
                <b-col lg="6">
                  <strong>Paseador:</strong> {{item.walkerName != '' ? item.walkerName : 'Por asignar'}}
                </b-col>                
                <b-col lg="6">
                  <CIcon name="cilDog" size="md" height="48" alt="Logo" />
                  {{item.petName}}
                </b-col>                 
                <b-col lg="6" v-if="+item.stateId == 34 && item.distance != '' ">                  
                  <strong>Distancia recorrida:</strong> {{item.distance}} km
                </b-col>             
              </b-row>
              <br>
              <b-row>                
                <b-col lg="4" offset-lg="4" sm="6">                  
                  <CommentsForm :serviceInfo = "item" v-if="item.stateId == 34"/>
                </b-col>  
                <b-col lg="4" sm="6" v-if="+item.stateId != 1">                  
                  <b-button 
                    class="mr-1"
                    size="sm"
                    style="width:100%;"
                    @click="openMap(item)"  >                    
                    Ver mapa y detalles <CIcon name="cilMap" size="sm" height="48" alt="Logo" />
                  </b-button> 
                </b-col>  
              </b-row>              
              <div class="card-header-actions">                             
                 
              </div>                                                                                                         
            </CCardHeader>

              <CCollapse :show="item.expandAccordion === 0">
                <CCardBody>
                  <b-row>
                    <b-col lg="6">
                      <strong>Id: </strong>{{item.id}} 
                    </b-col>                    
                  </b-row>
                </CCardBody>
              </CCollapse>                                       
          </CCard>
        </b-col>
      </b-row>
     
      <br>

    </section>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CommentsForm from '../comments/qualifyWalker'
export default {
  name: "Programaciones",
  components: {Loading, CommentsForm},  
  data () {
    return {
      loading: true,
      userProfile: null,
      hasUserProfile: false,
      userId: null,
      userDbId: null,      
      items: [],          
      dismissCountDown: 4,
      dismissSecs: 4,
      flagAlertProfile: false,
      flagAlertPetProfile: false,
      flagDataUpdated: false,
      flagAlertSchedule: false,
      wp: 'https://api.whatsapp.com/send?phone=573194115792&text=Hola Go Guau, ',
      paymentId: null,
      bannerImage: require("@/assets/images/bonds3.png"),
    }
  },

  created () {
    if(this.$route.query != null){
      let data = this.$route.query;
      this.paymentId = this.$route.params.id;                                         
    }
    
    this.userId = this.$store.getters.userId;
    this.userDbId = this.$store.getters.userDbId;
    let userProfile = JSON.parse(this.$store.getters.userProfile);

    if (userProfile){
      // console.log('userProfile:', userProfile)
      this.userProfile = userProfile      
      this.hasUserProfile = true
      if(userProfile.pets.length == 0){
        this.flagAlertPetProfile = true;
      }
      this.userDbId = this.userProfile.id      
      this.postGetClientSchedule()
    } else {
      this.loading = false;
      this.flagAlertProfile = true;
    }

    
  },
  methods: {
    postGetClientSchedule(){
      let payload = {
        id: this.paymentId
      }
      // console.log(payload)
      let getClientSchedule = this.$firebase.functions().httpsCallable('getClientScheduleByIdPayment')
      getClientSchedule(payload).then((result) => {
        var data = result.data.data
        // console.log(result)
        data.forEach(element => {
          switch (element.stateId) {
            case 2:
                element.stateName = 'En ejecución'
              break;
            case 34:
                element.stateName = 'Finalizado'
              break;
            case 67:
                element.stateName = 'Cancelado por lluvia'
              break;
            case 68:
                element.stateName = 'Cancelado genéricamente'
              break;
          
            default:
              element.stateName = element.stateName
              break;
          }
          element.expandAccordion = 1;
        });
        if(data.length == 0){
          this.flagAlertSchedule = true;
        }
        this.items = data
        this.totalRows = this.items.length;
        console.log(data)
        
        this.loading = false        
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
    refresh() {      
      this.loading = true
      this.userId = this.$store.getters.userId;
      this.userDbId = this.$store.getters.userDbId;
      let userProfile = JSON.parse(this.$store.getters.userProfile);

      if (userProfile){
        this.userProfile = userProfile      
        this.hasUserProfile = true
        this.userDbId = this.userProfile.id      
        this.postGetClientSchedule()
      } else {
        this.loading = false
      }
    },
    openMap(item, index, button)  {
      this.loading = true;
      // console.log(item.id)
      let payload = {
        serviceId: item.id
      }
      let getContextUrlTracking = this.$firebase.functions().httpsCallable('getUrlTracking')
      getContextUrlTracking(payload).then((result) => {
        const contextUrl = result.data.data                
        // let routeData = this.$router.push({path: '/services/gps?' + contextUrl});
        // window.open(routeData.href, '_blank');
        this.loading=false;
        this.$router.push({path: '/services/gps?' + contextUrl});
      }).catch((error) => {
        console.log(error)
      })
    },
    goTo(to){
      if(to == 'createProfile'){
        this.$router.replace("/users/profile");
      } else if(to == 'createPet') {
        this.$router.replace("/pets/form/0");
      }
    },
    whatsapp(){          
      let message = this.wp +
        ' deseo información sobre los paseos y adiestramientos.'; 
      window.open(message, '_blank');
    },
    qualifyWalker(item, index, button) {
      console.log(item)
    },
    colorHeader (state) {      
      switch (state) {
        case 1:
          return {color: '#f9b115'};
          break;
        case 2:
          return {color: '#2eb85c'};
          break;
        case 34:          
          return {color: '#e55353'};
          break;
      
        default:
          return {color: '#39f'};//blue
          break;
      }
    },
  },
}
</script>

<style scoped>

</style>